import { consts } from '../consts'

function getTitle(vm) {
  const { title } = vm.$options
  if (title) {
    return typeof title === 'function'
      ? title.call(vm)
      // check if title match a key o i18n traslations
      : vm.$te(title) ? vm.$t(title) : title
  }
}

const mixinPlugin = {
  name: 'mixinPlugin',
  components: {},

  computed: {},
  created() {
    const title = getTitle(this)
    if (title) {
      document.title = `${title} | DCA WalliD`
    }
  },
  methods: {
    async generatePNGfromSVG() {
      const el = document.getElementById('svg-background')
      // let width = 300,
      //   height = 300,
      const svg = new XMLSerializer().serializeToString(el) // convert SVG to string

      const svg64 = window.btoa(svg)
      const url = await this.base64SvgToBase64Png(svg64, 1500)
      async function getFileFromUrl(url, name, defaultType = 'image/png') {
        const response = await fetch(url)
        const data = await response.blob()
        return new File([data], name, {
          type: response.headers.get('content-type') || defaultType,
        })
      }
      const file = await getFileFromUrl(url, `background${svg64.slice(0, 16)}`)

      return await this.uploadPhoto(file, 'background')
      // const canvas = new OffscreenCanvas(width, height);
      // const ctx = canvas.getContext("2d");
      // const v = await Canvg.from(ctx, svg);

      // // Render only first frame, ignoring animations and mouse.
      // await v.render();

      // // const blob = await canvas.convertToBlob();
      // const pngUrl = URL.createObjectURL(blob);

      // console.log(pngUrl);
    },

    base64SvgToBase64Png(originalBase64, width) {
      return new Promise((resolve) => {
        const img = document.createElement('img')
        img.onload = function () {
          document.body.appendChild(img)
          const canvas = document.createElement('canvas')
          const ratio = img.clientWidth / img.clientHeight || 1
          document.body.removeChild(img)
          canvas.width = width
          canvas.height = width / ratio
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
          try {
            const data = canvas.toDataURL('image/png')
            resolve(data)
          }
          catch (e) {
            console.error(e)
            resolve(null)
          }
        }
        img.src = `data:image/svg+xml;base64,${originalBase64}`
      })
    },
    reduceText(text, length = 44, clamp = '...') {
      const splitAt = index => x => [x.slice(0, index), x.slice(index)]
      if (text.length > length) { return [splitAt(length)(text)[0] + clamp, text] }
      else { return [text] }
    },
    /**
     *
     * Forces scroll into element with @id and a given @offset
     */
    scrollIntoID(id, offset) {
      this.$log.debug('scrollIntoID', id, offset)

      const wrapper = document.getElementById('scroll')

      const parentViewableArea = {
        height: wrapper.clientHeight,
        width: wrapper.clientWidth,
      }
      const parentRect = wrapper.getBoundingClientRect()

      const element = document.getElementById(id)

      const childRect = element.getBoundingClientRect()

      const isViewable
        = childRect.top >= parentRect.top
        && childRect.top <= parentRect.top + parentViewableArea.height
      this.$log.debug('offsetPosition', offset)

      // if (!isViewable) {
      this.$log.debug('isViewable', isViewable)

      setTimeout(
        () =>
          wrapper.scrollBy({
            top: offset,
            left: 0,
            behavior: 'smooth',
          }),
        100,
      )
      // }
    },

    /**
     *
     * Forces scroll into element with error with @id and a given @offset
     */
    scrollIntoError(id, offset) {
      this.$log.debug('scrollIntoError', id, offset)

      const element = document.getElementById(id)

      const wrapper = document.getElementById('scroll')

      const parentRect = wrapper.getBoundingClientRect()
      // What can you see?
      const parentViewableArea = {
        height: wrapper.clientHeight,
        width: wrapper.clientWidth,
      }

      // Where is the child
      const childRect = element.getBoundingClientRect()
      // Is the child viewable?
      const isViewable
        = childRect.top >= parentRect.top
        && childRect.top <= parentRect.top + parentViewableArea.height

      // if you can't see the child try to scroll parent
      if (!isViewable) {
        // scroll by offset relative to parent
        const elementPosition = childRect.top - wrapper.scrollTop

        const offsetPosition = elementPosition - parentRect.top
        this.$log.debug('offsetPosition', offsetPosition)

        wrapper.scrollBy({
          top: offsetPosition,
          left: 0,
          behavior: 'smooth',
        })
      }
    },
    getEmail(email) {
      const name = email.substring(0, email.lastIndexOf('@'))
      const domain = email.substring(email.lastIndexOf('@') + 1)
      return { name, domain }
    },
    isEmailValid(email) {
      return email === '' ? '' : !!this.reg.test(email)
    },
    checkPageFocus() {
      return document.hasFocus()
    },
    scrollInto(id, offset) {
      this.$log.debug(id, offset)

      this.$nextTick(() => {
        const element = document.getElementById(id)
        const headerOffset = offset
        const elementPosition
          = element.getBoundingClientRect().top + window.scrollY
        const offsetPosition = elementPosition - headerOffset
        const behavior = 'smooth'
        window.scrollTo({
          top: offsetPosition,
          behavior,
        })
      })
    },
    getMethodName() {
      let error = {}
      try {
        // eslint-disable-next-line unicorn/error-message
        throw new Error('')
      }
      catch (e) {
        error = e
      }
      // IE9 does not have .stack property
      if (error.stack === undefined) { return '' }

      let stackTrace = error.stack.split('\n')[3]
      if (/ /.test(stackTrace)) { stackTrace = stackTrace.trim().split(' ')[1] }

      if (stackTrace && stackTrace.includes('.')) { stackTrace = stackTrace.split('.')[1] }

      return stackTrace
    },
    debug(a, ...args) {
      if (this) {
        const methodName = this.getMethodName()

        this.$log.debug(`${methodName} | `, a, ...args)
      }
      else {
        console.log(a, ...args)
      }
    },
    logError(a, ...args) {
      if (this) {
        const methodName = this.getMethodName()
        this.$log.error(`${methodName} | ${a}`, ...args)
      }
      else {
        console.error(a, ...args)
      }
    },
  },
  data() {
    return {
      WAITING_WALLET: consts.WAITING_WALLET,
      PENDING_APPROVAL: consts.PENDING_APPROVAL,
      APPROVED: consts.APPROVED,
      REVOKED: consts.REVOKED,
      ACTIVE: consts.ACTIVE,
      PENDING: consts.PENDING,

      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,24}))$/i,
    }
  },
}

export default mixinPlugin

export default {
  drivingLicense: {
    title: 'Carta de Condução',
    subtitle: 'Credencial de Condução',
    attributes: {
      name: 'Nome',
      email: 'Email',
      expirydate: 'Data de Expiração',
      nationality: 'Nacionalidade',
      dateofbirth: 'Data de Nascimento',
      licenseclass: 'Categoria da Carta',
      licensenumber: 'Número da Carta',
      address: 'Endereço',
      country: 'País',
      phonenumber: 'Número de Telefone',
      idnumber: 'Número de Identificação',
      procedencia: 'Procedência',
      restrictions: 'Restrições',
    },
    categories: 'Categorias',

  },
  pageTitle: {
    dashboard: 'Dashboard',
    profile: 'Perfil',
    issue: 'Emitir Credenciais',
    viewCredential: 'Ver Credenciais',
    pendingTasks: 'Aprovações Pendentes',
    settings: 'Definições',
    invite: 'Convidar Admin',
    signIn: 'Entrar',
    viewTemplate: 'Ver Template',
    issueCredential: 'Emitir Credencial',
  },
  step: ['Paso', 'de'],
  signin: {
    title: 'Crie e emita documentos de identificação digitais',
    button: 'Entrar',
  },
  error: {
    UsernameNotFoundError: 'Nome de utilizador não encontrado',
    WrongUsernamePasswordError: 'Nome de utilizador ou palavra-passe incorretos',
    default: 'Erro de servidor. Por favor tente novamente mais tarde.',
  },
  successModal: {},
  dashboard: {
    title: 'Dashboard',
    subtitle: 'Todos os templates de credenciais',
    emptyTemplate: 'Adicionar novo template',
    counters: {
      pending: 'Aprovações pendentes',
      revoked: 'Credenciais revogadas',
      templates: 'Templates criados',
      issued: 'Credenciais emitidas',
      approved: 'Credenciais Aprovadas',
    },
  },
  profile: {
    title: 'Perfil',
    linkBlockChain: 'Verificar Emissor de Credenciais na Blockchain ',
    issuerID: 'ID do Emissor de Credenciais na Blockchain',
    admins: {
      title: 'Todos os admins',
      tabs: ['Todos os admins', 'Masters', 'Managers'],
      menu: ['Adicionar novo Admin'],
    },
    credentials: {
      title: 'Templates de credenciais',
      menu: ['Adicionar novo template', 'Apagar template'],
    },
  },
  issue: {
    title: 'Emitir Credenciais',
    new: 'Emitir credenciais',
    menu: ['Emitir credenciais manualmente', 'Emitir credenciais por ficheiro'],
    wallet: 'Este membro já tem uma carteira MyWalliD',
    radio: ['Sim', 'Não'],
    walletField: 'Endereço da carteira MyWalliD do membro credenciado',
    emailField: 'Endereço de email do membro credenciado',
    emailHint:
      'Um email automático será gerado e enviado para este membro da sua comunidade para que ele possa criar uma carteira MyWalliD e armazenar a sua credencial.',
    button: 'Seguinte',
    IssueCredentials: 'Issue credentials manually',
    IssueInBulk: 'Issue credentials in bulk',
  },
  inBulkModal: {
    title: 'Emitir credenciais por ficheiro',
    text1:
      'Carregue um ficheiro com uma tabela contendo a informação dos membros respectiva a esta credencial.',
    text2:
      'Garanta que os atributos descritos no ficheiro correspondem aos que criou no template desta credencial e que aparecem pela mesma ordem.',
    text3:
      'Confirme os dados das credenciais a emitir e envie-as a todos os membros apresentados em baixo.',
    upload: ['Arraste ou ', 'carregue ', ' o seu ficheiro'],
    error:
      'Existem atributos no documento excel carregado que não correspondem aos atributos presentes no seu template da credencial.',
    errorFile:
      'Este tipo de formato não é suportado. Faça upload de um documento excel em formato CSV.',
    hint: 'Formato de ficheiros compatíveis: .csv',
  },
  createCertModal: {
    title: 'Adicionar novo template de credencial',
    upload: [
      'Importar logo do Emissor de Credenciais',
      'Max. file size: 5 MB',
      'apenas JPG ou PNG',
    ],
    errorUrlPhoto: 'Por favor carregue um ficheiro de imagem',
    uploadButton: 'Importar ficheiro de imagem',
    name: 'Nome de template de credencial',
    errorCertName: 'Por favor atribua um nome ao template da credencial',
    radio: ['Tipo de credencial', 'Frente', 'Frente e verso'],
    front: 'Frente',
    back: 'verso',
    frontSide: {
      add: ['Adicione atributos à credencial:', 'Adicionar'],
      dropdown: ['Atributo', 'Tipo'],
      placeholder: [
        'Selecione um atributo',
        'Selecione um tipo de atributo',
        'Escreva um atributo',
      ],
      mandatory: 'Obrigatório',
    },
    signatures: {
      add: ['Adicione assinaturas ao template de credenciais:', 'Adicionar'],
      dropdown: ['Nome', 'Cargo', 'estilo da assinatura'],
      hint: 'Primeiro e último nome apenas',
      placeholder: ['Insira um nome', 'Insira um cargo'],
      maxSigs:
        'Não é possível adicionar mais de 2 assinaturas a esta credencial',
      error: 'Por favor insira pelo menos uma assinatura',
    },
    backSide: {
      type: 'Tipo de atributo',
      add: ['Adicione atributos à credencial:', 'Adicionar'],
      dropdown: ['Atributo', 'Tipo', 'Tipo de input'],
      placeholder: [
        'Associe um atributo à coluna',
        'Selecione um tipo de atributo',
        'Escreva o atributo da coluna',
      ],
      maxColumns:
        'Não é possível adicionar mais de 3 colunas a esta credencial',
    },
    errorModule: 'Por favor adicione um módulo',
    errorType: 'Por favor adicione um tipo',
    errorAttribute: 'Por favor adicione um atributo',
    errorInput: 'Por favor associe um tipo de input ao atributo',
    publicField: ['Atributo publico', 'Seleccione atributo público'],
    errorPublicField:
      'Por favor escolha um atributo público. Não será uma funcionalidade visível mas vai ajudar-te a identificar esta credencial no meio de todas as outras emitidas e apresentadas pelo dashboard',
    hint: 'O atributo público deverá ser facilmente identificado pelos utilizadores desta plataforma.',
    card: 'Confirme o layout da credencial e se os atributos estão bem configurados.',

    hintCard:
      'Assim que criar este template de credencial não irá poder voltar atrás e editá-la.',
  },
  createCAModal: {
    title: 'Bem-vindo à WalliD para Emissores de Credenciais',
    subtitle: 'Vamos começar!',
    text1:
      'Parabéns! O poder de gerir uma entidade emissora de credenciais digitais está agora nas suas mãos. Percorra os passos seguintes para a configurar num instante',
    text2: 'Atribua um nome à sua Instituição ou comunidade:',
    caName: 'Nome da entidade emissora',
    email: 'E-mail',
    hint: 'Certifique-se que este é um nome reconhecido publicamente uma vez que será associado a todas as credenciais que vier a emitir, e não poderá ser alterado mais à frente',
    errorCaName: 'Por favor atribua um nome à entidade Emissora de Credenciais',
    errorEmail: 'Adicione um email',
  },
  cancelModal: {
    title: 'Cancelar credencial',
    text: 'De certeza que quer cancelar esta credencial? <br> Não será possível desfazer esta acção depois',
  },
  revokeModal: {
    title: 'Revogar Credencial',
    text: 'Tem certeza que quer revogar esta credencial? <br> Não será possível desfazer esta acção depois.',
    button: 'Revogar Credencial',
  },
  viewCredential: {
    tabs: ['Todas as credenciais', 'Pendentes', 'Aprovadas', 'Revogadas'],
    approved: 'Ainda não tem numa credencial aprovada.',
    pending: ' Nenhuma aprovação pendente.',
    revoked: ' Nenhuma credencial revogada.',
    noCredentials: [
      'Ainda não tem nenhuma credencial emitida.',
      'Emitir primeira credencial',
      'agora!',
    ],
    modal: {
      title: 'Ver credencial',
    },
    noPreview: 'Sem pré-visualização disponível neste momento',
    preview: 'Pré-visualização',
  },
  pendingTasks: {
    title: 'Aprovações pendentes',
    tabs: ['Credenciais pendentes ', 'Aprovada '],
    emptyTable: 'Sem aprovações pendentes por agora',
  },
  header: {
    dashboard: 'Dashboard',
    issuing: 'Perfil Emissor de Credenciais',
    settings: 'Definições',
    signOut: 'Sair',
  },
  footer: {
    language: 'Língua:',
    contacts: 'Contactos',
  },
  table: {
    search: 'Procurar por \'nome\' ou \'ID de credencial\' ',
    searchAdmin: 'Procurar por \'nome\' ou \'Nível\' ',
    roles: {
      MASTER: 'Master',
      MANAGER: 'Manager',
    },
    noData: 'Nenhum resultado encontrado',
    menu: {
      view: 'Ver credencial',
      download: 'Transferir credencial',
      approve: 'Aprovar credencial',
      revoke: 'Revogar credencial',
      cancel: 'Cancelar credencial',
    },
  },
  status: {
    waiting_wallet: 'À espera da carteira',
    pending_approval: 'Aprovação pendente',
    active: 'Aprovado',
    revoke: 'Revogado',
    pending: 'Pendente',
    activeA: 'Activo',
  },
  links: {
    copyright: 'Todos os Direitos Reservados. WalliD®',
    terms: 'Termos e condições',
    privacy: 'Política de Privacidade',
  },
  button: {
    next: 'Seguinte',
    back: 'Anterior',
    continue: 'Continuar',
    create: 'Adicionar template',
    issue: 'Emitir credencial',
    confirm: 'Confirmar',
    cancel: 'Cancelar Credencial',
  },
  modals: {
    confirm: {
      title: 'Confirm template creation',
      text: 'Are you sure about the attributes and the look of the credential you designed? Once you create this template you won’t be able to come back and edit it.',
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
    email: {
      title: 'Sucesso!',
      text: ' A credencial foi enviada com sucesso para o membro da sua comunidade. Poderá verificar e alterar o seu estado no dashboard.',
    },
    wallet: {
      title: 'Credencial emitida com sucesso.',
      text: 'A credencial foi emitida com sucesso para carteira MyWalliD do membro da sua comunidade.',
    },
    credential: {
      title: 'Credencial emitida com sucesso.',
      text: 'A credencial foi emitida com sucesso para carteira MyWalliD do membro da sua comunidade.',
    },
    credentials: {
      title: 'Credentials successfully sent',
      text: 'Your members have received an email with your issued credential and will now setup a MyWalliD wallet and store it in it. Once that is process is completed, you will be notified to approve the issuance and they will be able to use it anywhere online.',
    },
    successCreateTemplate: {
      title: 'Template successfully created',
      text: 'Your credential template was successfully created. Now you are ready to start issue credentials on your dashboard page.',
    },
  },
  tour: {
    title: [
      'Dashboard',
      'Credential Issuer profile',
      // 'Settings',
      'Pending approvals tab',
      'Credential templates',
      'Sign out',
    ],
    text: [
      'An overview on your Credential Issuer Entity activity. Access and complete all pending tasks from this dashboard.',
      'Manage your Credential Issuer Entity templates, admins and permissions.',
      // 'Access your account and check your permissions for this Credential Issuer Entity.',
      'Manage your quick and pending tasks such as approving or revoking issued credentials.',
      'Access and create credential templates for this Credential Issuer Entity.',
      'Go to MyWalliD wallet plugin through the icon on your browser to sign out.',
    ],
    skip: 'skip tour',
    next: 'next',
    finish: 'finish',
  },
}

// import People from "../views/People";
// import Welcome from "./../views/Welcome";
import Home from '../views/Home'
import SignIn from '../views/SignIn'
import SignUp from '../views/SignUp'
import Invite from '../views/Invite'
import Authenticate from '../views/Authenticate.vue'

import translationsTemplateEditor from '@/components/TemplateEditor/locales'

export const SIGNIN = '/SignIn'
export const SIGNUP = '/SignUp'
export const INVITE = '/Invite'

const routesList = ['IssuerProfile', 'Settings']

const children = routesList.map((route) => {
  switch (route) {
    default:
      return {
        path: route,
        name: route,
        component: () =>
          import(
            /* webpackChunkName: "[request]" */ `../views/${route}.vue`
          ),
        title: route,
        meta: {
          requiresAuth: true,
        },
      }
  }
})

export const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: 'Dashboard',
        name: 'Dashboard',
        component: () =>
          import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
        title: 'Dashboard',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'CredentialDesign',
        component: () =>
          import(
            /* webpackChunkName: "CredentialDesign" */ '../views/CredentialDesign'
          ),
        title: 'CredentialDesign',
        children: [
          {
            path: '',
            name: 'CredentialDesignView',
            component: () =>
              import(
                /* webpackChunkName: "CredentialDesignView" */ '../views/CredentialDesign/index.vue'
              ),
            title: 'View',
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'CreateTemplateView',
            name: 'CreateTemplateView',
            component: () =>
              import(
                /* webpackChunkName: "CreateTemplateView" */ '../views/CredentialDesign/CreateTemplateView.vue'
              ),
            title: 'CreateTemplateView',
            meta: {
              i18n: translationsTemplateEditor,
              requiresAuth: true,
            },
          },
          {
            path: 'ChoosePresetTemplate',
            name: 'ChoosePresetTemplate',
            component: () =>
              import(
                /* webpackChunkName: "ChoosePresetTemplate" */ '../views/CredentialDesign/ChoosePresetTemplate.vue'
              ),
            title: 'ChoosePresetTemplate',
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'Dashboard/ViewTemplate/:tid',
        component: () =>
          import(
            /* webpackChunkName: "ViewTemplate" */ '../views/ViewTemplate.vue'
          ),
        title: 'ViewTemplate',
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'ViewTemplate',
            component: () =>
              import(
                /* webpackChunkName: "View" */ '../views/ViewTemplate/View.vue'
              ),
            props: r => ({ tid: r.params.tid }),
            title: 'View',
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'IssueInBulk',
            name: 'IssueInBulk',
            component: () =>
              import(
                /* webpackChunkName: "IssueInBulk" */ '../views/ViewTemplate/IssueInBulk.vue'
              ),
            title: 'IssueInBulk',
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'IssueCredentials',
            name: 'IssueCredentials',
            component: () =>
              import(
                /* webpackChunkName: "IssueCredentials" */ '../views/ViewTemplate/IssueCredentials.vue'
              ),
            title: 'IssueCredentials',
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ].concat(children), // Join the rest of routes (children) from routesList
  },
  {
    path: SIGNIN,
    component: SignIn,
    title: SIGNIN,
  },
  {
    path: SIGNUP,
    component: SignUp,
    title: SIGNUP,
  },
  {
    path: INVITE,
    component: Invite,
    title: INVITE,
  },
  {
    path: '/authenticate/:token',
    name: 'Authenticate',
    component: Authenticate,
  },
  {
    path: '/verify/:assetId',
    name: 'Verify',
    component: () =>
      import(/* webpackChunkName: "Verify" */ '../views/Verify/index.vue'),
  },
  {
    path: '/*',
    redirect: '/Dashboard',
  },
]

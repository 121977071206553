import Vue from 'vue'
import Vuex from 'vuex'
// import router from '../router';

// Modules import
import { consts } from '../consts'
import * as modules from './modules'

import {
  ACCEPT,
  CREATE_CA,
  CREATE_TEMPLATE,
  DASHBOARD,
  GET_CA,
  GET_TEMPLATE,
  GET_USER,
  IMPORT_FILE,
  ISSUE_USER_CREDENTIAL,
  PROFILE,
  REVOKE,
  TEMPLATE,
  TEMPLATE_ITEM,
  UPDATE_CA,
  UPLOAD_DOC,
} from './actions'

const { useAxiosAuth } = require('../api/axios')

// USER STATES

const ROLES = {
  master: 'MASTER',
  manager: 'MANAGER',
}

Vue.use(Vuex)
const store = new Vuex.Store({
  modules,
  // initial state
  state: {
    adminEmail: null,
    errorMsg: null,
    isMaster: false,
    caName: null,
    cid: null,
    contractAddress: null,
    photoURL: null,
    photoHandle: null,
    templates: [],
    allPendingUsers: [],
    allowedTemplates: 1,
    allowedCredentials: 351,
    allApprovedCounter: 0,
    allAdminsTable: [],
    mastersTable: [],
    totalIssuedCredentials: 0,
    debug: null,
    loading: false,
    isLoading: true,
  },
  getters: {
    state: state => state,
    caAddress: state => state.contractAddress,
    isMaster: state => state.isMaster,
    caName: state => state.caName,
    photoURL: state => state.photoURL,
    photoFromDB: state => state.photoURL,
    cid: state => state.cid,
    allPendingUsers: state => state.allPendingUsers,
    allowedTemplates: state => state.allowedTemplates,
    allowedCredentials: state => state.allowedCredentials,
    templates: state => state.templates,
    photoHandle: state => state.photoHandle,
    allApprovedCounter: state => state.allApprovedCounter,
    totalIssuedCredentials: state => state.totalIssuedCredentials,
    allAdminsTable: state => state.allAdminsTable,
    mastersTable: state => state.mastersTable,
    errorMsg: state => state.errorMsg,
    adminEmail: state => state.adminEmail,
    loading: state => state.loading,
    isLoading: state => state.isLoading,
  },
  actions: {
    [PROFILE]: ({ commit, state }) => {
      state.debug('PROFILE ADMIN')
      return new Promise((resolve, reject) => {
        const body = {
          wa: state.auth.walletAddress,
        }
        state.debug('body', body)
        useAxiosAuth().post(PROFILE, {
          ...body,
        })
          .then((response) => {
            const data = response.data.data
            state.debug('PROFILE', data)

            if (data) {
              const admin = {
                name: data.username,
                role: data.no_dca ? ROLES.master : ROLES.manager,
                email: data.email,
                status: 'activeA',
              }
              commit('setAdminEmail', data.email)
              if (
                !state.allAdminsTable.some(e => e.username === admin.username)
              ) { commit('pushAdmin', admin) }

              if (data.no_dca) { commit('pushMaster', admin) }

              const balances = data.balances
              state.debug('balances', balances)
              if (balances[0]) {
                commit(
                  'allowedTemplates',
                  balances[0].balances.allowedTemplates,
                )
                commit(
                  'allowedCredentials',
                  balances[0].balances.allowedCredentials,
                )
                // }
              }
              resolve(response.status)
            }
            else {
              resolve(response.status)
            }
          })
          .catch((err) => {
            console.error(err)
            reject(new Error('No Profile'))
          })
      })
    },
    [ACCEPT]: ({ state }) => {
      state.debug('ACCEPT ADMIN')

      return new Promise((resolve, reject) => {
        const body = {
          invite_id: localStorage.getItem('invite_admin'),
          wa: state.auth.walletAddress,
        }
        state.debug('body', body)
        useAxiosAuth().post(ACCEPT, {
          ...body,
        })
          .then((response) => {
            state.debug('ACCEPT ADMIN response', response)
            resolve(response.status)
          })
          .catch((err) => {
            console.error(err)
            reject(err)
          })
      })
    },
    [REVOKE]: async ({ commit, state }, { user }) => {
      commit('setLoading', true)
      state.debug('REVOKE:user', user)

      try {
        const body = {
          id: user.id,
          waAdmin: state.auth.walletAddress,
          tid: user.tid,
        }

        const response = await useAxiosAuth().post(REVOKE, body)
        state.debug('REVOKE:response', response)
        commit('setLoading', false)
        return response
      }
      catch (error) {
        commit('setLoading', false)
        throw error
      }
    },
    [ISSUE_USER_CREDENTIAL]: ({ state }, { data, tid, email, imageArray }) => {
      state.debug('ISSUE_USER_CREDENTIAL')

      return new Promise((resolve, reject) => {
        const body = {
          cid: state.cid,
          tid,
          wa_admin: state.auth.walletAddress,
          data,
          email,
          imgArray: imageArray,
        }
        useAxiosAuth().post(ISSUE_USER_CREDENTIAL, {
          ...body,
        })
          .then((response) => {
            state.debug('ISSUE_USER_CREDENTIAL', response)
            resolve(response.status)
          })
          .catch((err) => {
            console.error(err)
            reject(err)
          })
      })
    },

    [GET_USER]: ({ state }, { id }) => {
      state.debug('GET_USER')
      return new Promise((resolve, reject) => {
        useAxiosAuth().get(`${GET_USER}?user_id=${id}`)
          .then((response) => {
            state.debug('GET_USER', response)
            resolve(response.data)
          })
          .catch((err) => {
            console.error(err)
            reject(err)
          })
      },
      )
    },

    [UPDATE_CA]: ({ commit, state }, { name, img_url }) => {
      return new Promise((resolve, reject) => {
        const body = {
          cid: state.cid,
          name,
          img_url,
        }
        useAxiosAuth().post(UPDATE_CA, {
          ...body,
        })
          .then((response) => {
            state.debug('UPDATE_CA', response)
            const data = response.data
            commit('setContractAddress', data.message.contract_address)

            commit('setCA_cid', data.message.cid)
            resolve(data.message.cid)
          })
          .catch((err) => {
            console.error(err)
            reject(err)
          })
      })
    },
    [CREATE_CA]: ({ commit, state }, { admin_email }) => {
      state.debug('CREATE_CA', admin_email)

      return new Promise((resolve, reject) => {
        // /CA { wallet } => ca_id
        const body = {
          wa: state.auth.walletAddress,
          admin_email,
        }
        useAxiosAuth().post(CREATE_CA, {
          ...body,
        })
          .then((response) => {
            state.debug('CREATE_CA', response)
            const data = response.data
            commit('setContractAddress', data.message.contract_address)
            commit('setAdminEmail', data.message.admin_email)
            resolve(data.message.cid)
          })
          .catch((err) => {
            if (err.response.status === 500) { reject(err.response.data) }

            reject(err)
          })
      })
    },

    [DASHBOARD]: async ({ commit, state }) => {
      state.debug('Action DASHBOARD')

      const compare = (a, b) => {
        const A = a.name.toUpperCase()
        const B = b.name.toUpperCase()

        let comparison = 0
        if (A > B) { comparison = 1 }
        else if (A < B) { comparison = -1 }

        return comparison
      }

      // This may cause bugs in reloading
      // commit('setCID', null)

      try {
        const response = await useAxiosAuth().get(DASHBOARD, {
          params: {
            wa: state.auth.walletAddress,
          },
        })
        state.debug('DASHBOARD', response)
        const data = response.data.data
        if (data.cid && data.ca_creator === state.auth.walletAddress) { commit('setMaster', true) }

        // this should be the default url img
        commit('setPhotoURL', data.img_url)

        const orderTemplates = [...data.templates]
        orderTemplates.sort(compare)
        const allPendingUsers = []
        let totalIssuedCredentials = 0

        orderTemplates.forEach((t) => {
          totalIssuedCredentials += t.users.length
          t.users.forEach((u) => {
            if (u.status === consts.PENDING_APPROVAL) {
              const user = u
              user.cred_name = t.name
              user.cid = t.cid
              user.frontendProps = t.frontendProps
              allPendingUsers.push(user)
            }
            if (u.status === consts.APPROVED) { commit('incAllApprovedCounter') }
          })
        })
        state.debug(allPendingUsers)

        commit('setTotalIssuedCredentials', totalIssuedCredentials)
        commit('setAllPendingUsers', allPendingUsers)
        commit('setContractAddress', data.contract_address)
        commit('setTemplates', orderTemplates)
        commit('setCaName', data.ca_name)
        commit('setCID', data.cid)

        return { cid: data.cid, templates: data.templates }
      }
      catch (error) {
        console.log('Error in DASHBOARD', error)
        throw error
      }
    },
    [IMPORT_FILE]: ({ state }, { tid, import_data }) => {
      state.debug('IMPORT_FILE call')
      return new Promise((resolve) => {
        const body = {
          cid: state.cid,
          tid,
          wa_admin: state.auth.walletAddress,
          import_data,
        }
        useAxiosAuth().post(IMPORT_FILE, {
          ...body,
        })
          .then((response) => {
            state.debug(`SUCCESS!! ${response.status}`)
            state.debug(response)
            resolve(response)
          })
          .catch((error) => {
            console.error(error)
          })
      })
    },
    [UPLOAD_DOC]: ({ state }, { formData }) => {
      state.debug('UPLOAD_DOC call')
      return new Promise((resolve, reject) => {
        useAxiosAuth().post(UPLOAD_DOC, formData)
          .then((response) => {
            state.debug(`SUCCESS!! ${response.status}`)
            state.debug(response)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    [CREATE_TEMPLATE]: ({ state }, { name, frontendProps }) => {
      return new Promise((resolve, reject) => {
        const body = {
          cid: state.cid,
          wa: state.auth.walletAddress,
          name,
          frontend_props: frontendProps,
        }

        useAxiosAuth().post(TEMPLATE, {
          ...body,
        })
          .then((response) => {
            state.debug(response)
            resolve(response.data)
          })
          .catch((err) => {
            state.debug(err)
            reject(err)
          })
      })
    },
    [GET_CA]: ({ state }) => {
      return new Promise((resolve) => {
        const body = {
          cid: state.cid,
          wa_admin: state.auth.walletAddress,
        }
        useAxiosAuth().post(GET_CA, {
          ...body,
        })
          .then((response) => {
            state.debug(response)
            resolve(response)
          })
          .catch((err) => {
            state.debug(err)
          })
      })
    },

    [TEMPLATE_ITEM]: ({ state }, { tid, attrs, tables }) => {
      return new Promise((resolve, reject) => {
        const body = {
          cid: state.cid,
          tid,
          wa: state.auth.walletAddress,
          attrs,
          tables,
        }
        useAxiosAuth().post(TEMPLATE_ITEM, {
          ...body,
        })
          .then((response) => {
            state.debug(response)
            resolve(response)
          })
          .catch((err) => {
            state.debug(err)
            reject(err)
          })
      })
    },
    [GET_TEMPLATE]: ({ state }, { tid }) => {
      return new Promise((resolve) => {
        useAxiosAuth().get(`${TEMPLATE}?tid=${tid}`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            state.debug(err)
          })
      })
    },
  },
  mutations: {
    isLoading: (state, value) => {
      state.isLoading = value
    },
    setLoading: (state, value) => {
      state.loading = value
    },
    initLogger: (state, func) => {
      state.debug = func
    },
    clearError: (state) => {
      state.errorMsg = null
    },
    errorMsg: (state, errorMsg) => {
      state.errorMsg = errorMsg
    },
    update_governance: (state, value) => {
      state.maxAdmins = value
    },
    allowedTemplates: (state, value) => {
      state.allowedTemplates = value
    },
    allowedCredentials: (state, value) => {
      state.allowedCredentials = value
    },
    pushMaster: (state, value) => {
      state.mastersTable.push(value)
    },
    pushAdmin: (state, value) => {
      state.allAdminsTable.push(value)
    },
    incAllApprovedCounter: (state) => {
      state.allApprovedCounter = state.allApprovedCounter + 1
    },
    setAdminEmail: (state, value) => {
      state.adminEmail = value
    },
    setTotalIssuedCredentials: (state, value) => {
      state.totalIssuedCredentials = value
    },
    setContractAddress: (state, value) => {
      state.contractAddress = value
    },
    setAllPendingUsers: (state, value) => {
      state.allPendingUsers = value
    },
    setTemplates: (state, templates) => {
      state.templates = templates
    },
    setMaster: (state, value) => {
      state.isMaster = value
    },
    setPhotoHandle: (state, value) => {
      state.photoHandle = value
    },
    setPhotoURL: (state, value) => {
      state.photoURL = value
    },
    setCaName: (state, value) => {
      state.caName = value
    },
    setCID: (state, value) => {
      state.cid = value
    },
    setCA_cid: (state, cid) => {
      state.cid = cid
    },
    updateIdentities(state, value) {
      state.identities = value
    },
  },
})

export default store

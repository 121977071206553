<script>
import { SIGN_IN } from '../store/actions'

import AppFooter from '../layout/AppFooter'

import sideImage from '../assets/wallid-background.jpg'

export default {
  name: 'SignIn',
  title: 'pageTitle.signIn',
  components: {
    AppFooter,
    LocalAuthentication: () => import('../components/Authentication/Local.vue'),
    // OAuthAuthentication: () => import('../components/Authentication/OAuth.vue'),
    // WalletAuthentication: () => import('../components/Authentication/Wallet.vue'),

  },
  data() {
    return {
      card: undefined,
      tab: 'one',
      sideImage,
      error: undefined,
    }
  },
  computed: {},
  mounted() {
    this.debug(`*** ${this.$options.name} ***`)
    this.$store.commit('isLoading', false)
  },
  methods: {
  },
}
</script>

<template>
  <v-container class="signin pa-0">
    <v-row>
      <v-col cols="6" class="pt-8 pb-8" style="padding-left: 6vw">
        <v-col cols="12" class="pt-8 pb-10 pl-9">
          <div class="d-flex align-center">
            <v-img
              alt="Vuetify Logo"
              class="shrink mr-2"
              contain
              src="../assets/logos/logo-credential-issuers.png"
              transition="scale-transition"
              width="160"
            />
          </div>
        </v-col>
        <v-col cols="12" class="pt-16 pb-5 pl-9">
          <h1 class="title_header">
            <!-- {{ 'Select a sign in option' }} -->
            {{ $t('signin.title') }}
          </h1>
        </v-col>
        <v-col cols="6" class="pt-1 pr-10 pl-9">
          <!-- <v-tabs
            v-model="tab"
            color="#0ea4b5"
          >
            <v-tab key="one">
              {{ $t('pageTitle.signIn') }}
            </v-tab>
            <v-tab key="two">
              OAuth
            </v-tab>
            <v-tab key="three">
              Wallet
            </v-tab>
          </v-tabs> -->

          <!-- <v-tabs-items v-model="tab">
            <v-tab-item key="one">
              <v-col cols="12" md="6" class="px-0"> -->
          <LocalAuthentication />
          <!-- </v-col>
            </v-tab-item>

            <v-tab-item key="two">
              <OAuthAuthentication />
            </v-tab-item>

            <v-tab-item key="three">
              <WalletAuthentication />
            </v-tab-item>
          </v-tabs-items> -->
        </v-col>
      </v-col>
      <v-col cols="6" class="pt-0">
        <v-img
          alt="Wallid Background"
          class="shrink mr-2"
          contain
          src="../assets/wallid-background.jpg"
          transition="scale-transition"
          min-width="864"
          min-height="100"
        />
      </v-col>
    </v-row>
    <AppFooter />
  </v-container>
</template>

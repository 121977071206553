import html2canvas from 'html2canvas'

// const A4Ratio = 1.4142

async function getFileFromUrl(url, name, defaultType = 'image/png') {
  console.log('Get File from URL', url, name)
  const response = await fetch(url)
  const data = await response.blob()

  // check if file has extension and remove # from name

  const fileName = name.includes('.') ? name : `${name}.png`.replace(/#/g, '')

  return new File([data], fileName, {
    type: response.headers.get('content-type') || defaultType,
  })
}

async function capture(element) {
  const options = {
    useCORS: true,
    logging: true,
    backgroundColor: 'transparent',
    scale: 3,
  }
  const canvas = await html2canvas(element, options)
  // convert canvas to file
  const dataUrl = canvas.toDataURL('image/png')
  const file = await getFileFromUrl(dataUrl, 'capture')
  return file
}

export { getFileFromUrl, capture }

const state = {
  frontendProps: null,
  excelTemplate: null,
  tid: null,
  caName: null,
  credName: null,
  urlPhoto: null,
  cardHeaders: null,
  templateItens: null,
  customTemplateName: 'templateEditor',
  template: {},
}
const mutations = {
  frontendProps: (state, value) => {
    state.frontendProps = value
  },
  excelTemplate: (state, value) => {
    state.excelTemplate = value
  },
  tid: (state, value) => {
    state.tid = value
  },
  caName: (state, value) => {
    state.caName = value
  },
  credName: (state, value) => {
    state.credName = value
  },
  urlPhoto: (state, value) => {
    state.urlPhoto = value
  },
  cardHeaders: (state, value) => {
    state.cardHeaders = value
  },
  templateItens: (state, value) => {
    state.templateItens = value
  },
  customTemplateName: (state, value) => {
    state.customTemplateName = value
  },
  template: (state, value) => {
    state.template = value
  },

}
const actions = {
  SET_TEMPLATE: ({ rootState, commit }, { template }) => {
    console.log('Action SET_TEMPLATE', template)
    commit('excelTemplate', template.excelTemplate)
    commit('tid', template.tid)
    commit('caName', rootState.caName)
    commit('credName', template.name)
    commit('urlPhoto', rootState.photoURL)
    commit('cardHeaders', template.cardHeaders)
    commit('templateItens', template.templateItens)
    if (template.frontend_props) {
      commit('frontendProps', template.frontend_props)
      commit('customTemplateName', template.frontend_props.customTemplateName)
    }
    commit('template', template)
  },
  CLEAR_TEMPLATE: ({ commit }) => {
    commit('excelTemplate', null)
    commit('tid', null)
    commit('caName', null)
    commit('credName', null)
    commit('urlPhoto', null)
    commit('cardHeaders', null)
    commit('templateItens', null)
    commit('frontendProps', null)
    commit('customTemplateName', 'templateEditor')
    commit('template', {})
  },
}

const getters = {
  frontendProps: state => state.frontendProps,
  excelTemplate: state => state.excelTemplate,
  tid: state => state.tid,
  caName: state => state.caName,
  credName: state => state.credName,
  urlPhoto: state => state.urlPhoto,
  cardHeaders: state => state.cardHeaders,
  templateItens: state => state.templateItens,
  customTemplateName: state => state.customTemplateName,
  template: state => state.template,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

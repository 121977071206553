<script>
import { AUTHENTICATE } from '../store/actions'

export default {
  async created() {
    const token = this.$route.params.token
    console.log('Authenticate created', token)
    await this.$store.dispatch(AUTHENTICATE, { token })
    this.$router.push('/Dashboard')
  },
}
</script>

<template>
  <div>Authenticating...</div>
</template>
